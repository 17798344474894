const React = require('react');
const PropTypes = require('prop-types');

const Page = require('nordic/page');

const Commons = require('@components/commons');
const CredentialProducts = require('@components/credential-products');
const CredentialHeader = require('@components/credential-header');
const UserDetailsCard = require('@components/user-details-card');

const { RebrandProvider } = require('@context/rebrand-context');

const BASE_CLASS_NAME = 'certificate';

const Certificate = (props) => {
  const { isMobile, siteId, userData, rebrand } = props;

  return (
    <Page className={BASE_CLASS_NAME} name="certificate" state={props}>
      <Commons {...props} name="certificate" />
      <RebrandProvider rebrand={rebrand}>
        <CredentialHeader isMobile={isMobile} siteId={siteId}>
          <UserDetailsCard siteId={siteId} user={userData} />
        </CredentialHeader>
        <CredentialProducts isMobile={isMobile} siteId={siteId} />
      </RebrandProvider>
    </Page>
  );
};

Certificate.propTypes = {
  isMobile: PropTypes.bool,
  rebrand: PropTypes.shape({
    isInRebrand: PropTypes.bool,
    colors: PropTypes.shape({
      ANDES_ACCENT_COLOR: PropTypes.string,
      ANDES_TEXT_COLOR_PRIMARY: PropTypes.string,
      CUSTOM_BRAND_COLOR: PropTypes.string,
      ANDES_TEXT_BRAND: PropTypes.string,
    }),
  }),
  siteId: PropTypes.string,
  userData: PropTypes.shape({
    address: PropTypes.shape({
      city: PropTypes.string,
      state: PropTypes.string,
    }),
    email: PropTypes.string,
    firstName: PropTypes.string,
    fullName: PropTypes.string,
    lastName: PropTypes.string,
    phone: PropTypes.string,
    pictureUrl: PropTypes.string,
  }),
};

module.exports = Certificate;
