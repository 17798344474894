// i18nLocalesOverride:['es-AR','es-MX','pt-BR']

const React = require('react');
const PropTypes = require('prop-types');

const { useI18n } = require('nordic/i18n');
const { useRebrandContext } = require('@context/rebrand-context');

const { Card } = require('@andes/card');
const { Image } = require('nordic/image');
const { Pill } = require('@andes/badge');
const { Text, Title } = require('@andes/typography');
const { Thumbnail } = require('@andes/thumbnail');

const { formatDateWithoutDay } = require('@utils/date');

const BASE_CLASS_NAME = 'user-details-card';
const BASE_CLASS_NAME_REBRAND = 'user-details-card--rebrand';

const UserDetailsCard = ({ siteId, user }) => {
  const { i18n } = useI18n();
  const { isInRebrand } = useRebrandContext();

  const hasAddress = !!user?.address?.city && !!user?.address?.state;

  const contactData = [
    hasAddress && {
      imageSrc: 'certificate/icon-map.svg',
      text: `${user.address.city} - ${user.address.state}`,
    },
    !!user.phone && {
      imageSrc: 'certificate/icon-phone.svg',
      text: user.phone,
    },
    !!user.email && {
      imageSrc: 'certificate/icon-email.svg',
      text: user.email,
    },
  ].filter(Boolean);

  return (
    <Card
      className={`${BASE_CLASS_NAME} ${isInRebrand ? BASE_CLASS_NAME_REBRAND : ''}`}
      shadow="elevated"
    >
      <ul>
        <li className={`${BASE_CLASS_NAME}__item`}>
          <Thumbnail badge={<Pill color="green" contentType="icon" />} size="40">
            {user.pictureUrl ? (
              <Image lazyload className={`${BASE_CLASS_NAME}__avatar`} src={user.pictureUrl} />
            ) : (
              <span
                className={`${BASE_CLASS_NAME}__avatar ${BASE_CLASS_NAME}__placeholder-avatar`}
              />
            )}
          </Thumbnail>

          <div>
            <Title component="h6" size="xs">
              {user.fullName}
            </Title>
            <Text color="secondary" size="s">
              {i18n.gettext(
                'Revendedor desde {0}',
                formatDateWithoutDay(user.associationDate, siteId),
              )}
            </Text>
          </div>
        </li>
        {contactData?.map((item) => (
          <li key={item?.text} className={`${BASE_CLASS_NAME}__item`}>
            <Image lazyload src={item?.imageSrc} />
            <Text size="s">{item?.text}</Text>
          </li>
        ))}
      </ul>
    </Card>
  );
};

UserDetailsCard.propTypes = {
  siteId: PropTypes.string,
  user: PropTypes.shape({
    address: PropTypes.shape({
      city: PropTypes.string,
      state: PropTypes.string,
    }),
    associationDate: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    fullName: PropTypes.string,
    lastName: PropTypes.string,
    phone: PropTypes.string,
    pictureUrl: PropTypes.string,
  }),
};

module.exports = UserDetailsCard;
