// i18nLocalesOverride:['es-AR','es-MX','pt-BR']

const React = require('react');
const PropTypes = require('prop-types');

const { useI18n } = require('nordic/i18n');
const { useRebrandContext } = require('@context/rebrand-context');

const { Image } = require('nordic/image');
const { Title } = require('@andes/typography');
const { Pill } = require('@andes/badge');

const BASE_CLASS_NAME = 'product-details';
const BASE_CLASS_NAME_REBRAND = 'product-details--rebrand';

const ProductDetails = ({ isMobile, product, siteId }) => {
  const { i18n } = useI18n();
  const { isInRebrand } = useRebrandContext();

  if (isMobile) {
    return (
      <div
        className={`${BASE_CLASS_NAME} ${isInRebrand ? BASE_CLASS_NAME_REBRAND : ''}`}
        data-siteid={siteId}
      >
        <Pill color={isInRebrand ? 'accent' : 'green'}>{product.tag}</Pill>
        <Title component="h3" size={isMobile ? 'm' : 'xl'}>
          {i18n.gettext(product.name)}
        </Title>
        {product.image && (
          <Image
            lazyload
            className={`${BASE_CLASS_NAME}__image`}
            src={`certificate/${product.image}`}
          />
        )}
      </div>
    );
  }

  return (
    <div
      className={`${BASE_CLASS_NAME} ${isInRebrand ? BASE_CLASS_NAME_REBRAND : ''}`}
      data-siteid={siteId}
    >
      {product.image && (
        <Image
          lazyload
          className={`${BASE_CLASS_NAME}__image`}
          src={`certificate/${product.image}`}
        />
      )}
      <div className={`${BASE_CLASS_NAME}__texts`}>
        <Pill color={isInRebrand ? 'accent' : 'green'}>{product.tag}</Pill>
        <Title component="h3" size={isMobile ? 'm' : 'xl'}>
          {i18n.gettext(product.name)}
        </Title>
      </div>
    </div>
  );
};

ProductDetails.propTypes = {
  isMobile: PropTypes.bool,
  product: PropTypes.shape({
    image: PropTypes.string,
    name: PropTypes.string,
    tag: PropTypes.string,
  }),
  siteId: PropTypes.string,
};

module.exports = ProductDetails;
