// i18nLocalesOverride:['es-AR','es-MX','pt-BR']

const React = require('react');
const PropTypes = require('prop-types');

const { useI18n } = require('nordic/i18n');
const { useRebrandContext } = require('@context/rebrand-context');

const { Text, Title } = require('@andes/typography');

const BASE_CLASS_NAME = 'credential-header';
const BASE_CLASS_NAME_REBRAND = 'credential-header--rebrand';

const CredentialHeader = ({ children, isMobile, siteId }) => {
  const { i18n } = useI18n();
  const { isInRebrand } = useRebrandContext();

  const isMLB = siteId === 'MLB';
  const isMLM = siteId === 'MLM';

  return (
    <header className={`${BASE_CLASS_NAME} ${isInRebrand ? BASE_CLASS_NAME_REBRAND : ''}`}>
      <div className={`${BASE_CLASS_NAME}__container`}>
        <div className={`${BASE_CLASS_NAME}__texts`}>
          <Title color={isInRebrand ? 'primary' : 'inverted'} component="h1" size="l">
            {isMLB
              ? i18n.jsx.gettext('Revendedor{0}autorizado{0}de Mercado Pago', {
                  tags: { 0: '<br />' },
                })
              : i18n.jsx.gettext('Representante{0}autorizado Point', { tags: { 0: '<br />' } })}
          </Title>
          <Text
            color={isInRebrand ? 'secondary' : 'inverted'}
            component="p"
            size={isMobile ? 's' : 'm'}
          >
            {isMLM
              ? i18n.gettext(
                  'Habla con tu representante para comprar lectores, obtener más información sobre otros productos o resolver tus dudas.',
                )
              : i18n.gettext(
                  'Habla con tu revendedor para comprar lectores, obtener más información sobre otros productos o resolver tus dudas.',
                )}
          </Text>
        </div>
        <div className={`${BASE_CLASS_NAME}__user-card`}>{children}</div>
      </div>
    </header>
  );
};

CredentialHeader.propTypes = {
  children: PropTypes.node,
  isMobile: PropTypes.bool,
  siteId: PropTypes.string,
};

module.exports = CredentialHeader;
